import Organization from 'client/scripts/data/Organization';

function enterOrganizationToLogin() {
  $('#organization-login-error').text('');

  const organizationInputVal = $(
    '.organization-login-container #organization-input'
  )
    .val()
    .trim();

  if (!organizationInputVal) {
    $('#organization-login-error').text('This field is not empty');
    return;
  }

  Organization.enterOrganization({ organizationSlug: organizationInputVal })
    .done(() => {
      window.location.href = `/${organizationInputVal}`;
    })
    .fail(error => {
      const errorMsg =
        error && error.responseText ? error.responseText : 'Error';

      $('#organization-login-error').text(errorMsg);
    });
}

function eventListeners() {
  $('.organization-login-container .submit-group-wrapper .btn').on(
    'click',
    function(e) {
      e.preventDefault();
      enterOrganizationToLogin();
    }
  );

  $('.organization-login-container #organization-input').keypress(function(e) {
    const key = e.which;
    if (key == 13) {
      e.preventDefault();
      // the enter key code
      enterOrganizationToLogin();
    }
  });
}

const EnterOrganizationSlugToLogin = (() => {
  const init = () => {
    eventListeners();
  };

  return { init };
})();

export default EnterOrganizationSlugToLogin;

const Organization = (() => {
  const enterOrganization = data => {
    return $.ajax({
      type: 'POST',
      url: `/api/v1/organization/check-existing-slug`,
      data,
    });
  };

  return { enterOrganization };
})();

export default Organization;
